import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    paymentValue: 50000,
    paymentMethod: 'once'
  },
  getters: {
    paymentValue: state => state.paymentValue,
    paymentMethod: state => state.paymentMethod
  },
  mutations: {
    setPaymentValue(state, value){
      state.paymentValue = value
    },
    setPaymentMethod(state, method){
      state.paymentMethod = method
    }
  },
  actions: {
  },
  modules: {
  }
})
