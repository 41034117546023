import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheck, faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { faTelegram, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import VueAos from 'vue-aos'
import VuePrlx from 'vue-prlx'


export const eventBus = new Vue();
library.add(faTelegram, faFacebook, faCheck, faCirclePlus, faCircleMinus, faInstagram, faYoutube);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueAos);
Vue.use(VuePrlx);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
