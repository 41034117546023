<template>
    <slick :options="settings">
        <div v-for="item in items" class="carousel__block">
            <div class="team__mates">
                <div   class="team__mates_item m-b-b">
                    <div class="team__mates_item_img-wrap">
                        <div class="team__mates_item_img" :style="{'background-image': 'url('+item.photo+')'}"></div>
                    </div>
                    <div class="team__mates_item_name text-big text-rounded">
                        <div>{{item.name}} {{item.surname}}</div>
                    </div>
                    <div class="team__mates_item_description text-normal p-x-s">
                        <div class="team__mates_item_description-text" v-html="item.text"></div>
                    </div>
                </div>
            </div>
        </div>
    </slick>
</template>

<script>
    import Slick from 'vue-slick';
    import 'slick-carousel/slick/slick.css';
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    export default {
        name: "teamSlider",
        components: {Slick},
        data() {
            return {
                settings: {
                    arrows: true,
                    autoplay: false,
                    dots: false,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '0',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 2,
                    variableWidth: false,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                rows: 1
                            }
                        }
                    ]
                },
                items: [
                    {
                        photo: '/home/team/roma.jpg',
                        name: 'Роман',
                        surname: 'Калниболоцький',
                        text:   'Голова благодійного фонду'
                    },
                    {
                        photo: '/home/team/ZayacMihayloMiroslavovich.jpg',
                        name: 'Михайло',
                        surname: 'Заяць',
                        text:   'Cпівзасновник фонду | Амбасадор '
                    },
                    {
                        photo: '/home/team/GolodovDenisValeriyovich.jpg',
                        name: 'Денис',
                        surname: 'Голодов',
                        text:   'Водій'
                    },
                    {
                        photo: '/home/team/SeverenchukOleksandr.jpg',
                        name: 'Олександр',
                        surname: 'Северенчук',
                        text:   'Водій'
                    },
                    {
                        photo: '/home/team/GalashDmitroMihaylovich.jpg',
                        name: 'Дмитро',
                        surname: 'Галаш',
                        text:   'Менеджер зовнішньо економічної діяльності'
                    },
                    {
                        photo: '/home/team/SirukSergiyPetrovich.jpg',
                        name: 'Сергій',
                        surname: 'Сірук',
                        text:   'Амбасадор'
                    },
                    {
                        photo: '/home/team/SarimsakovAndriyRamazanovich.jpg',
                        name: 'Андрій',
                        surname: 'Саримсаков',
                        text:   'Фотограф'
                    },
                    {
                        photo: '/home/team/GalmakovOleksandrPavlovich.jpg',
                        name: 'Олександр',
                        surname: 'Гальмаков',
                        text:   'Водій'
                    },
                    {
                        photo: '/home/team/GalmakovKonstantinPavlovich.jpg',
                        name: 'Константин',
                        surname: 'Гальмаков',
                        text:   'Амбасадор'
                    },
                    {
                        photo: '/home/team/BoykoIgor.jpg',
                        name: 'Ігор',
                        surname: 'Бойко',
                        text:   'Керівник митно брокерського відділу'
                    },
                    {
                        photo: '/home/team/FatihSahin.jpg',
                        name: 'Fatih',
                        surname: 'Sahin',
                        text:   'Міжнародний представник фонду'
                    },
                    {
                        photo: '/home/team/GoryaynovDanYuriyovich.jpg',
                        name: 'Дан',
                        surname: 'Горяйнов',
                        text:   'Менеджер закупок по Болгарії'
                    },
                    {
                        photo: '/home/team/VeselovskiyIvanYuriyovich.jpg',
                        name: 'Іван',
                        surname: 'Веселовський',
                        text:   'Водій'
                    },
                    {
                        photo: '/home/team/SergiySergiievIvanovich.jpg',
                        name: 'Сергій',
                        surname: 'Сергієв',
                        text:   'Волонтер'
                    },
                    {
                        photo: '/home/team/MindarievVitaliyOleksandrovich.jpg',
                        name: 'Віталій',
                        surname: 'Міндарєв',
                        text:   'Волонтер'
                    },
                    {
                        photo: '/home/team/ArzhanovAndrii.jpg',
                        name: 'Андрій',
                        surname: 'Аржанов',
                        text:   'Волонтер'
                    },
                    {
                        photo: '/home/team/ZaharchenkoSergiy.jpg',
                        name: 'Сергій',
                        surname: 'Захарченко',
                        text:   'Волонтер'
                    },
                    {
                        photo: '/home/team/OleksiyMikolayovichKurilyak.jpg',
                        name: 'Олексій',
                        surname: 'Куриляк',
                        text:   'Волонтер'
                    },
                    {
                        photo: '/home/team/ArzhanovaOlha.jpg',
                        name: 'Ольга',
                        surname: 'Аржанова',
                        text:   'Волонтер'
                    },
                    {
                        photo: '/home/team/ShishaPavlo.jpg',
                        name: 'Павло',
                        surname: 'Шиша',
                        text:   'Волонтер'
                    },
                    {
                        photo: '/home/team/AmafunskiyVYu.jpg',
                        name: 'Віталій',
                        surname: 'Амафунський',
                        text:   'Водій'
                    },
                    {
                        photo: '/home/team/BaranovskaYaroslavaIgorevn.jpg',
                        name: 'Ярослава',
                        surname: 'Барановська',
                        text:   'Медик'
                    },
                    {
                        photo: '/home/team/BarishevaKaterinaOleksandrivna.jpg',
                        name: 'Катерина',
                        surname: 'Баришева',
                        text:   'Спеціаліст з комунікацій та медіа'
                    },
                    {
                        photo: '/home/team/VatovDmitroPetrovich.jpg',
                        name: 'Дмитро',
                        surname: 'Ватов',
                        text:   'Амбасадор'
                    },
                    {
                        photo: '/home/team/GrechkaSergiyVitaliyovich.jpg',
                        name: 'Сергій',
                        surname: 'Гречка',
                        text:   'Фотограф'
                    },
                    {
                        photo: '/home/team/Dmitro.jpg',
                        name: 'Дмитро',
                        surname: 'Костюченко',
                        text:   'Амбасадор'
                    },
                    {
                        photo: '/home/team/ZorinOleksandrVadimovich.jpg',
                        name: 'Олександр',
                        surname: 'Зорін',
                        text:   'Волонтер, Адмінстратор'
                    },
                    {
                        photo: '/home/team/KropachevDO.jpg',
                        name: 'Дмитро',
                        surname: 'Кропачев',
                        text:   'Водій'
                    },
                    {
                        photo: '/home/team/MakarenkoOO.jpg',
                        name: 'Олександр',
                        surname: 'Макаренко',
                        text:   'Амбасадор'
                    },
                    {
                        photo: '/home/team/MalevaniyOleksandr.jpg',
                        name: 'Олександр',
                        surname: 'Малеваний',
                        text:   'Ремонт електроніки'
                    },
                    {
                        photo: '/home/team/MiroshnichenkoSergiy.jpg',
                        name: 'Сергій',
                        surname: 'Мірошниченко',
                        text:   'Керівник 3D моделінгу'
                    },
                    {
                        photo: '/home/team/PutickiyAntonIgorovich.jpg',
                        name: 'Антон',
                        surname: 'Путицький',
                        text:   'Амбасадор'
                    },
                    {
                        photo: '/home/team/SamoylenkoMaksimVadimovich.jpg',
                        name: 'Максим',
                        surname: 'Самойленко',
                        text:   'Фармацевт'
                    },
                    {
                        photo: '/home/team/YakubovskaGannaValeriyivna.jpg',
                        name: 'Ганна',
                        surname: 'Якубовська',
                        text:   'Дизайнер'
                    },
                ]
            };
        },
    }
</script>

<style lang="scss">
    @import "../vars";
    .slick-prev{
        display: none;
    }
    .slick-next{
        width: 60px;
        height: 60px;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
        border-radius: 50%;
        border: 2px solid $orange;
        &:before{
            content: url("../../public/misc/right_arrow.svg");
            width: 50px;
            height: 50px;
            line-height: 0;
        }
        &:hover{
            width: 65px;
            height: 65px;
        }
        &:focus{

        }
    }
    .team__mates{
        .team__mates_item{
            display: flex;
            flex-direction: row;
            .team__mates_item_img{
                width: 230px;
                height: 280px;
                background-size: cover;
                box-shadow: darken($softGreen, 10%) 20px 20px 0;
            }
            .team__mates_item_img-wrap{
                flex-grow: 1;
                flex-basis: 0;
            }
            .team__mates_item_name{
                flex-grow: 1;
                flex-basis: 0;
                justify-content: center;
                align-items: center;
                text-align: left;
                display: flex;
                letter-spacing: 2px;
                flex-direction: column;
                div{
                    width: 100px;
                }
            }
            .team__mates_item_description{
                flex-grow: 1.5;
                flex-basis: 0;
                display: flex;
                justify-content: left;
                align-items: center;
                .team__mates_item_description-text{
                    position: relative;
                    &:before{
                        position: absolute;
                        content: '';
                        width: 2px;
                        height: 100%;
                        background-color: $orange;
                        left: -12%;
                    }
                }
            }
        }
    }



    @media (max-width: $large_w) {
        .slick-next {
            right: 50px;
        }
    }

    @media (max-width: $medium_w) {
        .team__mates .team__mates_item .team__mates_item_img {
            width: 180px;
            height: 200px;
        }
        .team__mates .team__mates_item .team__mates_item_description .team__mates_item_description-text:before {
            left: -12%;
        }
    }

    @media (max-width: 800px) {
        .team__mates .team__mates_item .team__mates_item_img {
            width: 50vw;
            height: 60vw;
        }
        .team__mates .team__mates_item .team__mates_item_img-wrap {
            justify-content: center;
            display: flex;
        }
        .team__mates .team__mates_item {
            flex-direction: column;
        }
        .team__mates .team__mates_item .team__mates_item_description .team__mates_item_description-text:before {
            display: none;
        }
        .team__mates .team__mates_item .team__mates_item_name div {
            width: 80%;
            text-align: center;
            margin: 25px;
        }
        .slick-next {
            top: 63%;
            right: -2px;
        }
    }

    @media (max-width: $small_w) {

    }

    @media (max-width: $medium_small_w) {

    }

</style>
