<template>
    <div class="container donut__wrapper">
        <div class="donut__form" ref="helpBlock">
            <h1 class="p-y-b m-y-b text-shadow">Твій внесок рятує життя</h1>
            <div class="text-big p-b-b text-shadow" >{{this.description}}</div>
            <div class="text-rounded text-big text-orange text-shadow">Накопичено: {{this.amount}}</div>
            <div class="text-rounded text-big text-shadow">Ціль: {{this.goal}}</div>
            <div class="progress m-y-b">
                <progress ref='progress' value='0' max='100'></progress>
                <div class="progress__val text-rounded text-big">{{this.amount}} / {{this.percentage}}%</div>
            </div>

            <button class="donut__button m-y-m text-big text-rounded" @click="donate()">
                Підтримати
                <div class="pow-image"></div>
            </button>
            <div class="text-normal text-left p-b-b">твої <span class="text-orange">донати</span> допомогають хлопцям
                відоравляти орків до кобзона на концерт
            </div>

        </div>
        <div class="qr_wrapper">
            <div class="qr-container"> </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "monobanka",
        data() {
            return {
                description: '',
                amount: null,
                goal: null,
                percentage: 0
            }
        },
        methods: {
            async getData() {
                try {
                    let req = await axios.post('https://api.monobank.ua/bank/jar/4MMPdaKqnTB3K7XFMkfKvNVZjGe6THeR', {
                        colorScheme: "black",
                        sessionId: "670vovlvrowx4a33pzgcge",
                        type: "qrp"
                    });
                    this.description = req.data.description;
                    this.amount = this.formatNumber(req.data.amount);
                    this.goal = this.formatNumber(req.data.goal);
                    this.percentage = Math.round((req.data.amount*100)/req.data.goal)
                    if(this.$refs.progress){
                        this.percentage =
                            this.$refs.progress.value = this.percentage
                    }
                }catch (e) {
                    console.log(e);
                }
            },
            donate() {
                window.open('https://send.monobank.ua/jar/AHM46Thv95', '_blank', 'nofollow noreferrer');
            },
            formatNumber(number) {
                const formatter = new Intl.NumberFormat('uk-UA', {
                    style: 'currency',
                    currency: 'UAH',
                    minimumFractionDigits: 2,
                });
                return formatter.format(number / 100);
            }
        },
        async created() {
            this.getData();
            setInterval(() => {
                this.getData()
            }, 10000)
        }
    }
</script>

<style scoped lang="scss">
    @import "../vars";

    @mixin val() {
        border-radius: 20px;
        box-shadow: inset 0 .05em .05em rgba(#fff, .35);
        background: var(--fill)
    }
    .progress{
        position: relative;
        .progress__val{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-shadow: 1px 1px 2px #333333;
        }
    }
    .text-shadow{
        text-shadow: 0 0 4px #333333
    }
    progress {
        box-sizing: border-box;
        border: solid 5px #242b35;
        width: 100%; height: 40px;
        border-radius: 20px;
        background: linear-gradient(#191c23, #2d3341);

        &::-webkit-progress-bar { background: transparent }

        &::-webkit-progress-value { @include val() }
        &::-moz-progress-bar { @include val() }


        &:nth-child(1) {
            --fill:
                    linear-gradient(#e2d877, transparent 85%),
                    linear-gradient(90deg, #df8c00, #dd7325)
        }
    }
    .qr_wrapper{
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        justify-content: flex-end;
    }

    .qr-container {
        width: 400px;
        height: 400px;
        background-image: url('../../public/qr.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .donut__wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .donut__form {
        z-index: 1000;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 600px;
        color: $gray;

        h1 {
            font-size: 45px;
        }

        .donut__button {
            cursor: pointer;
            position: relative;
            width: 100%;
            height: 50px;
            border: none;
            background-color: $orange;
            background-repeat: no-repeat;
            background-position: right;
            background-size: 50px;
            color: white;
            font-size: 20px;
            -o-transition: .5s;
            -ms-transition: .5s;
            -moz-transition: .5s;
            -webkit-transition: .5s;
            transition: .5s;

            .pow-image {
                top: 0;
                right: 5px;
                position: absolute;
                width: 10%;
                height: 100%;
                background-image: url("../../public/pow.png");
                background-size: cover;
                background-position: center;
                transform-origin: 20% 70%
            }

            &:hover {
                color: #333333;
                background-color: lighten($orange, 15%);

                .pow-image {
                    animation: pow_hello 1s linear infinite;
                }
            }

            &:active {
                background-color: darken($orange, 20%);
            }
        }
    }

    @media (max-width: $medium_w) {
        .donut__wrapper{
            flex-direction: column;
        }
        .qr_wrapper{
            padding-bottom: 50px;
        }

    }
</style>
