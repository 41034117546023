<template>
    <div class="footer images-background" v-prlx.background.mobile="{ speed: 0.2 , background: true, fromBottom: true}">
        <div class="container">
            <div class="footer_heading">
                <p class="text-big p-b-s">
                    Можеш та хочеш допомогти?
                </p>
                <p class="text-big p-b-s">
                    Допомога потрібна тобі?
                </p>
            </div>
            <div class="footer_content">
                <div class="footer__text m-b-m">
                    <div class="text-big">Не зволікай, зв’яжись з нами</div>
                    <img class="footer__logo p-b-m" src="@/assets/footer_logo.png" alt="">
                </div>

                <div class="footer_info p-y-m text-normal">
                    <div>
                        <div >
                            <div class="text-underline-gray">Телефонуй:</div>
                        </div>
                        <a href="tel:+380992297043" class="text-bold text-white">+38 (099) 229 70 43</a>
                    </div>
                    <div >
                        <div >
                            <div class="text-underline-gray">Пиши:</div>
                        </div>
                        <a href="mailto: 3ayka.foundation@gmail.com" class="text-bold text-orange">3ayka.foundation@gmail.com</a>
                    </div>
                    <div class="footer__social">
                        <div >
                            <div class="text-underline-gray">Ми у соцмережах:</div>
                        </div>
                        <div class="p-t-s footer__social__icons">
                            <a href="https://t.me/zaykafoundation" target="_blank">
                                <font-awesome-icon class="top__contacts__icon text-orange p-r-s" icon="fa-brands fa-telegram" />
                            </a>
                            <a href="https://www.facebook.com/3ayka.foundation"  target="_blank">
                                <font-awesome-icon class="top__contacts__icon text-orange p-x-s" icon="fa-brands fa-facebook" />
                            </a>
                            <a href="https://www.instagram.com/3ayka.foundation"  target="_blank">
                                <font-awesome-icon class="top__contacts__icon text-orange p-x-s" icon="fa-brands fa-instagram" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCMiFtud8R1UEvz-faXK-bcA"  target="_blank">
                                <font-awesome-icon class="top__contacts__icon text-orange p-x-s" icon="fa-brands fa-youtube" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    export default {
        name: "SiteHeader"
    }
</script>

<style scoped lang="scss">
    @import "../vars";
    .footer{
        position: absolute;
        padding: 25px 0;
        right: 0;
        left: 0;
        bottom: -500px;
        width: 100%;
        .text-underline-gray{
            position: relative;
            margin-bottom: 30px;
            display: inline-block;
            &:after {
                content: '';
                height: 1px;
                width: 100%;
                background-color: white;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -8px;
            }
        }
        .footer_heading{
            text-align: left;
            color: white;
        }
        .footer_content{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: white;
            .footer__text{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);;
            }
            .footer__logo{
                width: 172px;
                height: 89px;
            }
            .footer_info{
                display: flex;
                flex-direction: row;
                text-align: left;
                align-items: center;
                justify-content: space-between;
            }
            .footer__social{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .top__contacts__icon{
                    font-size: 30px;
                    &:hover{
                        transform: scale(1.2,1.2);
                        -o-transition:.5s;
                        -ms-transition:.5s;
                        -moz-transition:.5s;
                        -webkit-transition:.5s;
                        transition:.5s;
                    }
                }
            }
        }
    }

    @media (max-width: $medium_w) {
        .footer{
            .text-underline-gray{
                margin-bottom: 15px;
                &:after{
                    bottom: -4px
                }
            }
            .footer_content{
                img{
                    display: none;
                }
                .footer_info{
                    width: 100%;
                    .m-l-b{
                        margin: 0;
                    }
                    div{
                        justify-content: center;
                        flex-grow: 1;
                        text-align: center;
                    }
                }
                .footer__text{
                    padding-bottom: 10px;
                }
                .footer__social{
                    align-items: center;
                }
                .footer__social__icons{
                    width: 100%;
                }
            }
        }

    }

    @media (max-width: $small_w) {
        .footer{
            bottom: -450px;
            .footer_content{
                .footer_info{
                    flex-direction: column;
                    .m-l-b{
                        margin-bottom: 15px;
                    }
                    .p-t-s{
                        padding: 0;
                    }
                }
            }
        }
    }

</style>
