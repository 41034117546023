import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/details',
    name: 'Details',
    component: () => import('../views/Details.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue')
  },
  {
    path: '/partner',
    name: 'Partners',
    component: () => import('../views/Partners.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/Payment.vue')
  },
  {
    path: '*',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition){
    if (isSamePage(from.fullPath, to.fullPath)) return savedPosition;
    return { x: 0, y: 0 }
  }
});

function isSamePage(from, to) {
  let toLocArray = to.split("/");
  let fromLocArray = from.split("/");
  let toLoc = toLocArray.at(-1);
  let fromLoc = fromLocArray.at(-1);
  // if (
  //     i18n.global.availableLocales.includes(toLoc) &&
  //     (i18n.global.availableLocales.includes(fromLoc) || fromLoc === "")
  // ) {
  //   return true;
  // }
  if (toLoc === fromLoc) return true;
  return false;
}

export default router
