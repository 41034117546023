<template>
    <div class="home">
        <section class="help" v-prlx.background.mobile="{ speed: 0.07 , background: true}">
            <div class="box-shadow"></div>
            <monobanka></monobanka>
        </section>
<!--        <section class="help" v-prlx.background.mobile="{ speed: 0.07 , background: true}">-->
<!--            <div class="box-shadow"></div>-->
<!--            <monobanka></monobanka>-->
<!--            <div class="container help__wrapper">-->
<!--                    <div class="help__form" ref="helpBlock">-->
<!--                        <h1 class="p-y-b">Твій внесок рятує життя</h1>-->
<!--                        <div class="help__form_option">-->
<!--                            <div-->
<!--                                    v-for="formMethod in formMethods"-->
<!--                                    class="help__form_option_item text-normal"-->
<!--                                    v-bind:class="{active: formMethod.name == form.method}"-->
<!--                                    @click="form.method = formMethod.name"-->
<!--                            >{{formMethod.title}}</div>-->
<!--                        </div>-->
<!--                        <div class="text-normal m-y-b">-->
<!--                            обери суму допомоги:-->
<!--                        </div>-->
<!--                        <div class="help__form_prices">-->
<!--                            <div class="help__form_prices_line">-->
<!--                                <input-->
<!--                                        placeholder="інша сума"-->
<!--                                        type="number"-->
<!--                                        v-model="formPrices[4]"-->
<!--                                        class="help__form_price another text-big another__input"-->
<!--                                        v-bind:class="{active: formPrices[4] == form.total}"-->
<!--                                        @input="setInputVal()"-->
<!--                                        @click="setInputVal()">-->
<!--                            </div>-->
<!--                            <div class="help__form_prices_line text-rounded">-->
<!--                                <div-->
<!--                                        class="help__form_price text-big"-->
<!--                                        v-bind:class="{active: formPrices[0] == form.total}"-->
<!--                                        @click="form.total = formPrices[0]"-->
<!--                                >{{formPrices[0]}}</div>-->
<!--                                <div-->
<!--                                        class="help__form_price text-big"-->
<!--                                        v-bind:class="{active: formPrices[1] == form.total}"-->
<!--                                        @click="form.total = formPrices[1]">{{formPrices[1]}}</div>-->
<!--                                <div-->
<!--                                        class="help__form_price text-big"-->
<!--                                        v-bind:class="{active: formPrices[2] == form.total}"-->
<!--                                        @click="form.total = formPrices[2]">{{formPrices[2]}}</div>-->
<!--                            </div>-->
<!--                            <div class="help__form_summary text-rounded">{{form.total}} грн</div>-->
<!--                            <button class="help__form_button m-y-m text-big text-rounded" @click="donut()">Підтримати <div class="pow-image"></div></button>-->
<!--                            <div class="text-normal text-left p-b-b">твої <span class="text-orange">{{form.total}} грн</span> допомогають хлопцям відоравляти орків до кобзона на концерт</div>-->
<!--                        </div>-->

<!--                    </div>-->
<!--            </div>-->
<!--        </section>-->
        <section class="who container p-y-b of-hidden">
            <h2 v-vue-aos.once="{animationClass:'fadeInLeft animated'}" class="head-underline-orange">Хто ми:</h2>
            <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal">
                Команда волонтерів та патріотів, які зосереджують свої зусилля на наданні підтримки та допомоги українській армії у її важкій боротьбі з Російською Федераціїєю. Усвідомлюючи важливість, ми створили цей фонд з метою забезпечення наших захисників необхідними ресурсами та підтримкою, необхідною для їхньої ефективної роботи.
            </p>
            <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal">
                Наша місія полягає у зборі коштів, які ми використовуємо для закупівлі сучасного військового спорядження, медичного обладнання та інших необхідних речей, які допомагають українській армії ефективно протистояти агресії.
            </p>
            <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal">
                Ми віримо в силу єднання та підтримки громадськості. Тому, крім збору фінансових коштів, ми активно залучаємо волонтерів, які допомагають нам у різних сферах - від організації заходів до збору та доставки необхідних речей на передову. Ми прагнемо залучити якомога більше людей до нашого руху, оскільки разом ми сильніші і здатні зробити більше для захисту нашої Батьківщини.
            </p>
            <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal">
                Кожна пожертва, будь вона великою чи маленькою, має велике значення. Ми відкриті до співпраці з будь-якими людьми, організаціями та підприємствами, які бажають долучитись до нашого фонду і внести свій внесок у підтримку української армії.
            </p>
            <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal">
                Дякуємо вам за вашість інтерес та підтримку. Разом ми можемо зробити більше для наших воїнів та нашої країни. Приєднуйтесь до "3aйка Foundation" вже сьогодні і допоможіть нам забезпечити успіх і свободу для України!
            </p>
        </section>
        <section class="why images-background p-y-b of-hidden" v-prlx.background.mobile="{ speed: 0.09 , background: true, fromBottom: true}">
            <div class="container why__items">
                <div class="why__text">
                    <h2 v-vue-aos.once="{animationClass:'fadeInLeft animated'}" class="head-underline-gray">Чому ми:</h2>
                    <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal">У нашому фонді ми маємо глибоке переконання, що справжня сила нації полягає в її об'єднанні та взаємодопомозі. Україна зазнає випробувань від російської агресії, і ми зрозуміли, що наша місія полягає у наданні необхідної допомоги та підтримки українській армії у цьому складному часі.</p>
                    <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal">Точкове надання допомоги, це швидка і корисна допомога. Це допомога великим фондом фокусуватись на їхніх задачах а в цей час такі ми є інше плече допомоги, тому чим більше тим краще!</p>
                    <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal">Ефективне використання ресурсів: тому що фонд не є дуже великим а має правильні зв'язки і відносини.</p>
                </div>
            </div>
        </section>
<!--        <section class="carousel container p-t-b">-->
<!--            <slick :options="settings">-->
<!--                <div  class="carousel__block" v-for="slide in slides">-->
<!--                    <img class="carousel__image" :src=slide.image alt="">-->
<!--                </div>-->
<!--            </slick>-->
<!--        </section>-->
        <section class="what container p-t-b of-hidden">
            <h2 v-vue-aos.once="{animationClass:'fadeInLeft animated'}"
                class="head-underline-orange">Що робимо:</h2>
            <h3 v-vue-aos.once="{animationClass:'fadeInLeft animated'}" >Результати Ви можете побачити в галереях та соц мережах, та по звітності оцінити нашу роботу!</h3>
            <ul v-vue-aos.once="{animationClass:'fadeInRight animated', selector: 'li'}"
                class="fa-ul text-normal">
                <li class="p-y-s"><span class="fa-li"><font-awesome-icon icon="fa-solid fa-check"/></span>
                    допомога постраждалим від наслідків російської агресії, передача генераторів, теплих речей, продуктів харчування
                </li>
                <li class="p-y-s"><span class="fa-li"><font-awesome-icon icon="fa-solid fa-check"/></span>
                    купівля та ремонт автомобілів на потреби військових
                </li>
                <li class="p-y-s"><span class="fa-li"><font-awesome-icon icon="fa-solid fa-check"/></span>
                    закупівля дронів, рацій, тепловізорів, сухих харчових наборів, засобів особистої гігієни на потреби військових
                </li>
                <li class="p-y-s"><span class="fa-li"><font-awesome-icon icon="fa-solid fa-check"/></span>
                    закупівля та передача військовим старлінкві, маскувальних сіток, планшетів, ноутбуків, термобілизни, ліків тактичної медицини
                </li>
                <li class="p-y-s"><span class="fa-li"><font-awesome-icon icon="fa-solid fa-check"/></span>
                    закупівля та передача ліків у лікарні
                </li>
            </ul>
        </section>
        <section class="m-y-b text-rounded">
            <collage/>
        </section>
        <section class="team container p-y-b ">
            <div class="of-hidden">
                <h2 v-vue-aos.once="{animationClass:'fadeInLeft animated'}" class="head-underline-orange">Наша команда:</h2>
                <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal m-b-b">
                    Ми - дружня та віддана команда людей, які присвятили себе підтримці української армії у війні з Росією. Наша команда складається з різних фахівців та патріотів, які об'єднали свої зусилля з метою забезпечити нашим захисникам необхідну допомогу та підтримку.
                </p>
                <p  v-vue-aos.once="{animationClass:'fadeInRight animated'}" class="text-normal m-b-b">
                    Разом з "3aйка Foundation" ви можете долучитись до нашої команди та стати частиною цієї благородної місії. Приєднуйтесь до нас вже сьогодні і допоможіть нам разом забезпечити успіх та безпеку для нашої країни!
                </p>
            </div>
            <team-slider/>
        </section>
        <section class="separator m-b-b" v-prlx.background="{ speed: 0.05 , background: true}">
            <div v-vue-aos="{animationClass:'jackInTheBox animated'}" class="separator__text text-rounded">
                наближаємо перемогу разом
            </div>
        </section>
        <section class="video container">
            <div class='embed-container m-b-b'>
                <iframe src='https://www.youtube.com/embed/ykRUW1guH7w?controls=0&color=ffbe4f'
                        frameborder='0' allowfullscreen></iframe>
            </div>
        </section>
    </div>
</template>

<script>
    import Slick from 'vue-slick';
    import 'slick-carousel/slick/slick.css';
    import {mapMutations} from 'vuex'
    import {eventBus} from '../main'
    import Collage from '../components/collage'
    import teamSlider from "../components/teamSlider";
    import monobanka from "../components/monobanka";
    import * as _ from 'lodash';
    import axios from "axios";

    export default {
        name: 'Home',
        components: {Slick, Collage, teamSlider, monobanka},
        data() {
            return {
                min: 20,
                max: 10000000,
                form: {
                    method: 'regular',
                    total: 1000,
                },
                formPrices: [1000, 800, 500],
                formMethods:[{name: 'regular', title: 'щомісячна допомога'}, {name: 'once', title: 'разова допомога'} ],
                slides: [
                    { image: "/home/slider/img1.webp" },
                    { image: "/home/slider/img2.webp" },
                    { image: "/home/slider/img3.webp" },
                    { image: "/home/slider/img4.webp" },
                    { image: "/home/slider/img5.webp" },
                ],
                settings: {
                    arrows: false,
                    autoplay: true,
                    dots: false,
                    infinite: true,
                    centerMode: true,
                    slidesToShow: 1,
                    variableWidth: true
                }
            };
        },
        methods: {
            ...mapMutations(['setPaymentValue', 'setPaymentMethod']),
            donut(){
                this.setPaymentValue(this.form.total * 100);
                this.setPaymentMethod(this.form.method);
                this.$router.push('/payment')
            },
            scrollMeTo(refName) {
                let element = this.$refs[refName];
                if(element){
                    let top = element.offsetTop-50;
                    window.scrollTo(0, top);
                }
            },
            setInputVal(){
                if(_.isNil(this.formPrices[4])){
                    this.formPrices[4] = this.form.total;
                }
                if(this.formPrices[4]< this.min){
                    this.form.total = this.min
                }else if(this.formPrices[4]> this.max){
                    this.form.total = this.max;
                    this.formPrices[4] = this.max;
                }else{
                    this.form.total = this.formPrices[4]
                }
            }
        },
        async created () {
            eventBus.$on('help', data => {
                this.scrollMeTo('helpBlock')
            })

            let a = await axios.post('https://api.monobank.ua/bank/jar/4MMPdaKqnTB3K7XFMkfKvNVZjGe6THeR', {
                colorScheme:"black",
                sessionId:"670vovlvrowx4a33pzgcge",
                type:"qrp"
            })
            console.log(a);
        }
    }
</script>

<style lang="scss">
    @import "../vars";
    .head-underline-gray{
        position: relative;
        display: inline-block;
        margin-bottom: 15px;
        &:after{
          content: '';
          height: 2px;
          width: 100%;
          background-color: $gray;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;
        }
    }
    .head-underline-orange{
        position: relative;
        display: inline-block;
        margin-bottom: 15px;
        &:after{
            content: '';
            height: 2px;
            width: 100%;
            background-color: $orange;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 10px;
        }
    }
    .help {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;
        background-image: url("../../public/bg/help_bg.webp");
        /*background-attachment: fixed;*/
        background-size: auto 125%;
        .help__wrapper{
            width: 100%;
            z-index: 1000;
        }
        .box-shadow{
            position: absolute;
            height: 100%;
            width: 100%;
            -moz-box-shadow: inset 0 -100px 200px -140px #000000;
            -webkit-box-shadow: inset 0 -100px 200px -140px #000000;
            box-shadow: inset 0 -100px 200px -140px #000000;
        }
        .help__form {
            z-index: 1000;
            flex-grow: 1;
            flex-basis: 0;
            max-width: 500px;
            color: $gray;
            h1 {
                font-size: 45px;
            }

            .help__form_option {
                justify-content: space-between;
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                gap: 30px;
                .help__form_option_item {
                    cursor: pointer;
                    width: 100%;
                    padding: 10px;
                }

                .help__form_option_item:not([class*="active"]) {
                    position: relative;

                    &:after {
                        position: absolute;
                        content: '';
                        height: 1px;
                        background: $softGreen;
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 0;
                        -o-transition: .5s;
                        -ms-transition: .5s;
                        -moz-transition: .5s;
                        -webkit-transition: .5s;
                        transition: .5s;
                    }

                    &:hover {
                        &:after {
                            position: absolute;
                            content: '';
                            height: 1px;
                            background: $softGreen;
                            margin: 0 auto;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            width: 100%;
                        }
                    }
                }

                .active {
                    position: relative;
                    color: $green;
                    background-color: $softGreen;
                }
            }

            .help__form_prices {
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                .help__form_prices_line {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    box-sizing: border-box;
                    column-gap: 10px;
                    margin-bottom: 10px;
                }
                .help__form_summary{
                    font-size: 50px;
                    color: $gray;
                    text-align: left;
                    padding-top:20px;
                }
                .help__form_price {
                    box-sizing: border-box;
                    padding: 10px;
                    width: 33%;
                    border: 1px $gray solid;
                    cursor: pointer;

                    &:hover:not([class*="active"]) {
                        background-color: lighten($green, 20%);
                        color: #eeeeee;
                        -o-transition: .5s;
                        -ms-transition: .5s;
                        -moz-transition: .5s;
                        -webkit-transition: .5s;
                        transition: .5s;
                    }
                }
                .another {
                    width: 100%;
                }
                .another__input{
                    outline: none;
                    border: 1px solid $gray;
                    background:none;
                    text-align: center;
                    color: $gray;
                    border-radius: 0;
                    -webkit-border-radius: 0;
                    -webkit-appearance: none;
                }
                input{
                    font-family: 'arial_rounded', sans-serif;
                    &::placeholder{
                        color: $gray;
                        font-family: 'arial_rounded', sans-serif;
                    }
                }
                .active {
                    color: $green;
                    background-color: $gray;
                }
            }

            .help__form_button {
                cursor: pointer;
                position: relative;
                width: 100%;
                height: 50px;
                border: none;
                background-color: $orange;
                background-repeat: no-repeat;
                background-position: right;
                background-size: 50px;
                color: white;
                font-size: 20px;
                -o-transition: .5s;
                -ms-transition: .5s;
                -moz-transition: .5s;
                -webkit-transition: .5s;
                transition: .5s;
                .pow-image{
                    top: 0;
                    right: 5px;
                    position: absolute;
                    width: 10%;
                    height: 100%;
                    background-image: url("../../public/pow.png");
                    background-size: cover;
                    background-position: center;
                    transform-origin: 20% 70%
                }
                &:hover {
                    color: #333333;
                    background-color: lighten($orange, 15%);
                    .pow-image{
                        animation: pow_hello 1s linear infinite;
                    }
                }

                &:active {
                    background-color: darken($orange, 20%);
                }
            }
        }
    }
    .who {
        text-align: left;
    }
    .collage{
        .collage__items-small{
            display: flex;
            flex-direction: row;
            flex-basis: 0;
            flex-grow: 1;
        }
        .collage__items{
            display: flex;
            flex-direction: row;
            .collage__img{
                height: 24vw;
                width: 100%;
                background-size: cover;
            }
            .image-green{
                width: 100%;
                height: 100%;
                color: white;
                background-color: rgba(202, 210, 187, 0.9);
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .image-white{
                width: 100%;
                height: 100%;
                color: $green;
                background-color: rgba(255, 255, 255, 0.9);
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .image__text_total{
                font-size: 80px;
            }
            .image__text_theme{
                font-size: 25px;
                padding-top: 5px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
            .image__text_transferred{
                line-height: 25px;
                font-size: 14px;
                text-transform: uppercase;
            }
        }
    }
    .team{
        text-align: left;
    }
    .separator{
        background-image: url("../../public/home/bg_separator.webp");
        background-size: cover;
        background-position: center;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        .separator__text{
            text-transform: uppercase;
            font-size: 50px;
            color: white;
            font-weight: 700;
        }
    }
    .why {
        text-align: left;
        color: white;
        .why__items{
            display: flex;
            flex-direction: row;
            .why__text{
                flex-basis: 0;
                flex-grow: 1;
                margin: auto;
            }
        }
    }
    .what {
        text-align: left;

        ul {
            li {
                span {
                    color: $orange;
                }
            }
        }
    }
    .video {
        .embed-container {
            position: relative;
            padding-bottom: 56.5%;
            height: 0;
            overflow: hidden;
            max-width: 100%;
        }

        .embed-container iframe, .embed-container object, .embed-container embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .carousel{
        .carousel__image{
            margin: 0 15px;
            filter: grayscale(0.9);
            -o-transition:.5s;
            -ms-transition:.5s;
            -moz-transition:.5s;
            -webkit-transition:.5s;
            transition:.5s;
        }
        .carousel__block{
            overflow: hidden;
        }
        .slick-active{
            .carousel__image{
                filter: grayscale(0)!important;
                transform: scale(1.1);
            }
        }
    }

    @supports (-webkit-touch-callout: none) {
        .help {
        }
    }

    @media (max-width: $extra_large_w) {

    }

    @media (max-width: $large_w) {
        .help{
            .help__form{
                h1{
                    font-size: 35px;
                }
            }
            .help__wrapper{
                width: 95%;
            }
        }
        .why{
            .why__items{

            }
        }
    }

    @media (max-width: $medium_w) {
        .help{
            .help__form{
                h1{
                    font-size: 30px;
                }
            }
        }
        .separator .separator__text {
            font-size: 35px;
        }
    }

    @media (max-width: $small_w) {
        .help{
            .help__form{
                margin-left: 0;
                h1{
                    font-size: 35px;
                }
                .help__form_option{
                    gap: 0;
                    width: 90%;
                }
            }
        }
        .separator .separator__text {
            font-size: 24px;
        }
    }

    @media (max-width: $medium_small_w) {
        .help{
            .help__form{
                margin-left: 0;
                h1{
                    font-size: 25px;
                }
                .help__form_option{
                    flex-direction: column;
                    align-items: center;
                    div{
                        margin-bottom: 30px;
                        width: 50%;
                    }
                }
                .help__form_prices{
                    .help__form_summary{
                        font-size: 30px;
                        text-align: center;
                    }
                    .help__form_prices_line{
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 0;
                    }
                    .help__form_price {
                        width: 80%;
                        margin-bottom: 10px;
                    }
                    .another {
                        width: 80%;
                    }
                }
            }
        }
        .separator .separator__text {
            font-size: 20px;
        }
    }
</style>
