<template>
  <div id="app">
    <site-header/>
    <transition name='slide' mode="out-in">
      <router-view/>
    </transition>
    <site-footer/>
  </div>
</template>
<script>
    import SiteHeader from '@/components/SiteHeader.vue'
    import SiteFooter from '@/components/SiteFooter.vue'
    export default {
        name: 'Home',
        components: {
            SiteHeader, SiteFooter
        }
    }
</script>


<style lang="scss">
@import "./clean.scss";
@import "./main.scss";
</style>
