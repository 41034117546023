<template>
    <div class="collage">
        <div
                v-for="(item, i) in collageItems"
                class="collage__img"
                :style="{'background-image': 'url(' + item.img +')'}"
                @mouseover.prevent="itemHover(i)"
                @mouseleave.prevent="itemHoverEnd(i)"
        >
            <div v-bind:class="{green: item.color === 'green', white: item.color === 'white'}" class="college__item" :ref="'item_'+i">
                <div class="image__text_total">{{item.val}}</div>
                <div class="image__text_theme">{{item.text}}</div>
                <div class="image__text_transferred">{{item.summary}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {TweenLite} from 'gsap'

    export default {
        name: "collage",
        data() {
            return {
                collageItems: [
                    {
                        img: '/home/collage/1.webp',
                        total: 200,
                        val: 0,
                        text: 'Starlinks',
                        summary: 'передано на фронт',
                        color: 'green',
                        hovered: false,
                        offset: 0
                    },
                    {
                        img: '/home/collage/2.webp',
                        total: 120,
                        val: 0,
                        text: 'рюкзаки',
                        summary: 'передано на фронт',
                        color: 'white',
                        hovered: false,
                        offset: 0
                    },
                    {
                        img: '/home/collage/3.webp',
                        total: 20,
                        val: 0,
                        text: 'Авто',
                        summary: 'передано на фронт',
                        color: 'green',
                        hovered: false,
                        offset: 0
                    },
                    {
                        img: '/home/collage/4.webp',
                        total: 300,
                        val: 0,
                        text: 'Аптечки',
                        summary: 'передано на фронт',
                        color: 'white',
                        hovered: false,
                        offset: 0
                    },
                    {
                        img: '/home/collage/5.webp',
                        total: 50,
                        val: 0,
                        text: 'Генераторів',
                        summary: 'передано на фронт',
                        color: 'green',
                        hovered: false,
                        offset: 0
                    },
                    {
                        img: '/home/collage/6.webp',
                        total: 10,
                        val: 0,
                        text: 'тон смаколиків',
                        summary: 'передано на фронт',
                        color: 'white',
                        hovered: false,
                        offset: 0
                    },
                    {
                        img: '/home/collage/7.webp',
                        total: 700,
                        val: 0,
                        text: 'зарядних пристроїв',
                        summary: 'передано на фронт',
                        color: 'green',
                        hovered: false,
                        offset: 0
                    },
                    {
                        img: '/home/collage/8.webp',
                        total: 180,
                        val: 0,
                        text: 'комплектів захисту',
                        summary: 'передано на фронт',
                        color: 'white',
                        hovered: false,
                        offset: 0
                    }
                ],
                mobileBreakpoint: 480,
                isMobile: false,
                scroll: 0
            }
        },
        methods: {
            itemHover(index) {
                if (!this.collageItems[index].hovered) {
                    this.collageItems[index].val = 0;
                    this.collageItems[index].hovered = true;
                    TweenLite.to(this.collageItems[index], 0.5, {
                        val: this.collageItems[index].total,
                        roundProps: "val",
                    });
                    this.$refs[`item_${index}`][0].style.opacity = 1
                }
            },
            itemHoverEnd(index) {
                this.collageItems[index].hovered = false;
                this.$refs[`item_${index}`][0].style.opacity = 0
            },
            handleScroll() {
                if(this.isMobile){
                    this.scroll = window.scrollY + window.innerHeight/2 -window.innerWidth/2;
                    for(let i = 0; i < this.collageItems.length; i++){
                        if(this.scroll > this.collageItems[i].offset && this.scroll < this.collageItems[i].offset + window.innerWidth){
                            this.itemHover(i);
                        }else {
                            this.itemHoverEnd(i);
                        }
                    }
                }
            },
            handleResize() {
                this.isMobile = window.innerWidth <= this.mobileBreakpoint;
                if(this.$refs['item_0']){
                    for(let i = 0; i < this.collageItems.length; i++){
                        this.collageItems[i]['offset'] = this.$refs[`item_${i}`][0].offsetTop;
                    }
                }
            },
        },
        computed: {

        },
        created() {
            window.addEventListener("scroll", this.handleScroll);
            window.addEventListener("resize", this.handleResize);
            this.handleResize();
        },
        mounted(){
            this.handleResize();
        },
        unmounted() {
            window.removeEventListener("resize", this.handleResize);
        },

    }
</script>

<style scoped lang="scss">
    @import "../vars";

    .collage {
        display: flex;
        flex-wrap: wrap;

        .college__item {
            opacity: 0;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            &:hover{
                opacity: 1;
            }
        }

        .collage__img {
            height: 24vw;
            width: 25%;
            background-size: cover;
        }

        .collage__items-small {
            display: flex;
            flex-direction: row;
            flex-basis: 0;
            flex-grow: 1;
        }

        .green {
            width: 100%;
            height: 100%;
            color: white;
            background-color: rgba(202, 210, 187, 0.9);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .white {
            width: 100%;
            height: 100%;
            color: $green;
            background-color: rgba(255, 255, 255, 0.9);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .image__text_total {
            font-size: 80px;
        }

        .image__text_theme {
            font-size: 25px;
            padding-top: 5px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        .image__text_transferred {
            line-height: 25px;
            font-size: 14px;
            text-transform: uppercase;
        }

    }

    @media (max-width: $extra_large_w) {

    }

    @media (max-width: $large_w) {

    }

    @media (max-width: $medium_w) {
        .collage {
            .collage__img {
                height: 50vw;
                width: 50%;
            }
        }
    }

    @media (max-width: $small_w) {

    }

    @media (max-width: $medium_small_w) {
        .collage {
            .image__text_total {
                font-size: 50px;
            }

            .collage__img {
                height: 80vw;
                width: 100%;
            }

            .image__text_theme {
                font-size: 15px;
                padding-top: 5px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }

            .image__text_transferred {
                line-height: 25px;
                font-size: 10px;
                text-transform: uppercase;
            }
        }
    }
</style>
