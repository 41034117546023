<template>
    <div class="header">
        <div class="top" ref="headerTop">
            <div class="top__right text-normal container">
                <div class="top__contacts">
                    <div>Контакти: </div>
                    <a href="tel:+380992297043" class="p-x-m text-bold text-gray">+38 (099) 229 70 43</a>
                    <div>|</div>
                    <a href="mailto: 3ayka.foundation@gmail.com" class="p-x-m text-bold text-orange">3ayka.foundation@gmail.com</a>
                </div>
                <div class="top__social">
                    <div class="p-x-s">Ми у соцмережах:</div>
                    <a href="https://t.me/zaykafoundation" target="_blank">
                        <font-awesome-icon class="top__contacts__icon text-orange p-x-s" icon="fa-brands fa-telegram" />
                    </a>
                    <a href="https://www.facebook.com/3ayka.foundation"  target="_blank">
                        <font-awesome-icon class="top__contacts__icon text-orange p-x-s" icon="fa-brands fa-facebook" />
                    </a>
                    <a href="https://www.instagram.com/3ayka.foundation"  target="_blank">
                        <font-awesome-icon class="top__contacts__icon text-orange p-x-s" icon="fa-brands fa-instagram" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCMiFtud8R1UEvz-faXK-bcA"  target="_blank">
                        <font-awesome-icon class="top__contacts__icon text-orange p-x-s" icon="fa-brands fa-youtube" />
                    </a>
                </div>
            </div>
        </div>
        <div class="shadow__wrapper" ref="navigation" :style="{top: getTopOffset + 'px'}" :class="{mobile__background:isMobileMenuOpen}" >
            <div class="main container" :class="{'main--minimized':isMenuMinimized || isMobile }">
                <router-link
                    tag="a"
                    to="/" class="main__logo" href="">
                    <img src="@/assets/logo.png" alt="">
                    <div class="main__logo__text">
                        <img src="@/assets/logo-text.png" alt="">
                    </div>
                </router-link>
                <nav class="main__menu" v-if="!isMobile">
                    <ul class="main__menu_items">
                        <li class="main__menu_item text-big">
                            <router-link
                                    tag="a"
                                    to="/"
                            >Про нас</router-link>
                        </li>
                        <li class="main__menu_item text-big">
                            <router-link
                                    tag="a"
                                    to="/details"
                            >Реквізити</router-link>
                        </li>
<!--                        <li class="main__menu_item text-big">-->
<!--                            <router-link-->
<!--                                    tag="a"-->
<!--                                    to="/faq"-->
<!--                            >Часті запитання</router-link>-->
<!--                        </li>-->
                        <li class="main__menu_item text-big">
                            <router-link
                                    tag="a"
                                    to="/partner"
                            >Партнери</router-link>
                        </li>
                    </ul>
                </nav>
                <button type="button" class="main__menu_button text-big text-rounded"  @click="goHelp()">Підтримати <div class="pow-image"></div></button>
                <div class="site-header-burger" v-if="isMobile">
                    <toggle-menu-button
                            @toggleMobileMenu="toggleMobileMenu"
                            color="#7F8F5E"
                            :isActive="isMobileMenuOpen"
                    />
                </div>
            </div>
            <div v-if="isMobileMenuOpen">
                <nav class="main__menu__mobile">
                    <ul class="main__menu_items" @click="menuOptionClick()">
                        <li class="main__menu_item text-big">
                            <router-link
                                    tag="a"
                                    to="/"
                            >Про нас</router-link>
                        </li>
                        <li class="main__menu_item text-big">
                            <router-link
                                    tag="a"
                                    to="/details"
                            >Реквізити</router-link>
                        </li>
<!--                        <li class="main__menu_item text-big">-->
<!--                            <router-link-->
<!--                                    tag="a"-->
<!--                                    to="/faq"-->
<!--                            >Часті запитання</router-link>-->
<!--                        </li>-->
                        <li class="main__menu_item text-big">
                            <router-link
                                    tag="a"
                                    to="/partner"
                            >Партнери</router-link>
                        </li>
                    </ul>
                </nav>
                <div class="mobile__contacts">
                    <a href="tel:+380992297043" class="p-x-m text-bold text-gray">+38 (099) 229 70 43</a>
                    <a href="mailto: 3ayka.foundation@gmail.com" class="p-x-m text-bold text-orange">3ayka.foundation@gmail.com</a>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {eventBus} from '../main'
    import toggleMenuButton from "./toggleMenuButton";

    export default {
        name: "SiteHeader",
        components: {
            toggleMenuButton
        },
        data() {
            return {
                scroll: 0,
                panelHeight: 71,
                isMobile: false,
                isMobileMenuOpen: false,
                isMenuMinimized: false,
            }
        },
        methods: {
            goHelp(){
                if(this.isMobileMenuOpen){
                    this.toggleMobileMenu()
                }
                if(this.$route.path!== '/'){
                    this.$router.push('/');
                }
                eventBus.$emit('help')
            },
            handleScroll() {
                this.scroll = window.scrollY;
                if (this.scroll > this.panelHeight) {
                    if (!this.isMenuMinimized) this.isMenuMinimized = true;
                } else {
                    if (this.isMenuMinimized) this.isMenuMinimized = false;
                }
            },
            toggleMobileMenu(){
                this.isMobileMenuOpen = !this.isMobileMenuOpen;
                if (this.isMobileMenuOpen) {
                    document.documentElement.style.overflow = "hidden";
                } else {
                    document.documentElement.style.overflow = "inherit";
                }
            },
            handleResize() {
                this.isMobile = window.innerWidth <= 980;
                if(this.$refs.headerTop)
                this.panelHeight = this.$refs.headerTop.clientHeight;
            },
            menuOptionClick(){
                this.isMobileMenuOpen = false;
                document.documentElement.style.overflow = "inherit";
            }
        },

        mounted() {
            this.panelHeight = this.$refs.headerTop.clientHeight;
        },
        created() {
            window.addEventListener("scroll", this.handleScroll);
            window.addEventListener("resize", this.handleResize);
            this.handleResize();
        },
        unmounted() {
            window.removeEventListener("resize", this.handleResize);
        },
        computed: {
            isMenuOpen() {
                if (this.windowWidth >= 1025) return true;
                return this.isMobileMenuOpen;
            },
            getTopOffset(){
                if(this.panelHeight - this.scroll > 0) {
                    return this.panelHeight - this.scroll
                } else {
                    return 0
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "../vars";
    .header{
        margin-bottom: 188px;
    }
    .top {
        background: $softGreen;
        padding: 20px 0;
        .top__right{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .top__contacts{
                display: flex;
                flex-direction: row;
            }
            .top__social{
                display: flex;
                flex-direction: row;
                align-items: center;
                .top__contacts__icon{
                    font-size: 30px;
                    &:hover{
                        transform: scale(1.2,1.2);
                        -o-transition:.5s;
                        -ms-transition:.5s;
                        -moz-transition:.5s;
                        -webkit-transition:.5s;
                        transition:.5s;
                    }
                }
            }
        }
    }
    .site-header-burger {
        justify-content: center;
        display: flex;
        align-items: center;
    }
    .shadow__wrapper{
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);
        position: fixed;
        background: white;
        width: 100%;
        z-index: 10000;
    }
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .shadow__wrapper {
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
    .main{
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       align-items: center;
       padding: 25px 0;
      .main__logo{
          display: flex;
          flex-direction: row;
          align-items: center;
          .main__logo__text{
              img{
                  margin-top: 20px;
                  margin-left: -10px
              }
          }
      }
    }
    .main--minimized{
        .main{
            padding: 0;
        }
        padding: 5px 25px;
        .main__logo{
            img{
                height: 50px;
            }
            .main__logo__text{
                img{
                    height: 25px;
                    margin-top: 9px;
                    margin-left: 0px;
                }
            }
        }
        .main__menu_button{
            margin: 0;
            height: 40px;
            background-repeat: no-repeat;
            background-position: right;
            background-size: 30px;
        }
        .main__menu_items{
            ul, ol {
                padding: 0 0 0.25em 2.5em;
            }
            padding: 0;
        }
    }
    .mobile__background{
        height: 100vh;
    }
    .mobile__contacts{
        display: none;
    }
    .main__menu__mobile{
        justify-content: center;
        display: flex;
        align-items: center;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
        .main__menu_items {
            list-style: none;
            display: flex;
            flex-direction: column;
            padding: 0;
            .main__menu_item{
                margin: 4vh;
                position: relative;
                a{
                    text-decoration: none;
                    color: $dark;
                    &:after{
                        position: absolute;
                        content: '';
                        height: 1px;
                        background: orange;
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                        bottom: -4px;
                        width: 0;
                        -o-transition:.5s;
                        -ms-transition:.5s;
                        -moz-transition:.5s;
                        -webkit-transition:.5s;
                        transition:.5s;
                    }
                }

                .router-link-exact-active {
                    &:after{
                        position: absolute;
                        content: '';
                        height: 2px;
                        background: orange;
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                        bottom: -4px;
                        width: 100%;
                    }
                }
            }
        }
    }
    .main__menu{
        display: flex;
        align-items: center;
        .main__menu_items {
            list-style: none;
            display: flex;
            flex-direction: row;
            .main__menu_item{
                margin: 0 20px;
                position: relative;
                a{
                    text-decoration: none;
                    color: $dark;
                    &:after{
                        position: absolute;
                        content: '';
                        height: 1px;
                        background: orange;
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                        bottom: -4px;
                        width: 0;
                        -o-transition:.5s;
                        -ms-transition:.5s;
                        -moz-transition:.5s;
                        -webkit-transition:.5s;
                        transition:.5s;
                    }
                }

                    .router-link-exact-active {
                        &:after{
                            position: absolute;
                            content: '';
                            height: 2px;
                            background: orange;
                            margin: 0 auto;
                            left: 0;
                            right: 0;
                            bottom: -4px;
                            width: 100%;
                        }
                    }
                a{
                    &:not([class*="router-link-exact-active"]) {
                        &:hover {
                            &:after {
                                position: absolute;
                                content: '';
                                height: 1px;
                                background: orange;
                                margin: 0 auto;
                                left: 0;
                                right: 0;
                                bottom: -4px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .main__menu_button{
        cursor: pointer;
        position: relative;
        width: 300px;
        height: 50px;
        border: none;
        background-color: $orange;
        color: white;
        font-size: 20px;
        margin-top: -10px;
        -o-transition:.5s;
        -ms-transition:.5s;
        -moz-transition:.5s;
        -webkit-transition:.5s;
        transition:.5s;
        .pow-image{
            top: 0;
            right: 5px;
            position: absolute;
            width: 15%;
            height: 100%;
            background-image: url("../../public/pow.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            transform-origin: 20% 70%
        }
        &:hover{
            color: #333333;
            background-color: lighten($orange, 15%);
            .pow-image{
                animation: pow_hello 1s linear infinite;
            }
        }
        &:active{
            background-color: darken($orange, 20%);
        }
    }

    @media (max-width: $extra_large_w) {
        .container{
            margin: 0 50px;
        }
    }

    @media (max-width: $large_w) {
        .header {
            margin-bottom: 150px;
        }
        .main__menu_button{
            width: 200px!important;
        }
        .main__logo{
            img{
                height: 100px;
            }
            .main__logo__text{
                img{
                    height: 50px;
                }
            }
        }
        .main__menu{
            .main__menu_items{
                ul, ol{
                    padding: 0;
                }
                padding: 0;
            }
        }
    }

    @media (max-width: $medium_w) {
        .main__menu_button{
            width: 200px!important;
            font-size: 15px;
            margin: 0 35px 0 auto!important;
        }
        .header{
            margin-bottom: 60px;
            .top{
                padding: 5px 0;
                .text-normal{
                    font-size: 12px;
                }
            }
        }
        .top{
            .top__right{
                .top__contacts{
                    display: none;
                }
            }

        }
        .mobile__contacts{
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            bottom: 45px;
            left: 50%;
            transform: translate(-50%, 0);
            a{
                font-size: 12px;
            }
        }
    }

    @media (max-width: $small_w) {

    }

    @media (max-width: $extra_small_w) {
        .header{
            margin-bottom: 50px;
        }
        .main{
            padding: 0;
            .main__logo__text{
                display: none;
            }
            .main__menu_button {
                width: 50%!important;
            }
        }
    }
</style>
